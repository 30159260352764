<template>
  <div id="app" class="app starting" :key="appKey" v-bind="app">
    <UpdateAvailable />
    <router-view />
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapGetters } from "vuex";
  import UpdateAvailable from "@/app/UpdateAvailable.vue";
  import icons from "@/common/icons.js";

  export default {
    components: { UpdateAvailable },
    data() {
      return {
        appKey: 1,
      };
    },
    metaInfo() {
      let title = "Models1A - Estudio de modelos WebCam";

      let description =
        "Models 1A es la agencia de modelaje webcam más experta de Colombia, con más de 10 años creando talentos exitosos en plataformas privadas. Ofrecemos formación, seguridad y las mejores oportunidades laborales desde casa.";

      let keywords =
        "modelo webcam Colombia, trabajo desde casa webcam, agencia de modelos webcam, empleo webcam Bogotá, Medellín, carrera en modelaje webcam, streaming profesional para modelos, cómo ganar dinero como modelo webcam, estudios de modelaje webcam en Colombia, privacidad y seguridad para modelos webcam, equipo de streaming para modelos, capacitación profesional en modelaje webcam, éxito en la industria del modelaje online";

      const currentUrl = "https://models1a.com";

      if (this.locale) {
        const { home = {} } = this.locale;

        title = home.title;
        description = home.description;
        keywords = home.keywords;
      }

      const meta = [
        { vmid: "description", name: "description", content: description },
        { vmid: "keywords", name: "keywords", content: keywords },
        { vmid: "og:title", property: "og:title", content: title },
        { vmid: "og:description", property: "og:description", content: description },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:url", property: "og:url", content: currentUrl },
        { vmid: "viewport", name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=5.0" },
        { vmid: "robots", name: "robots", content: "index, follow" },
      ];

      const link = [{ vmid: "canonical", rel: "canonical", href: currentUrl }];

      if (process.env.NODE_ENV !== "production") {
        return {
          title,
          meta,
          link,
        };
      }

      return {
        title,
        meta,
        link,
        script: [
          {
            src: "/js/gtag.js",
            head: true,
            async: false,
          },
          {
            src: "https://www.googletagmanager.com/gtag/js?id=G-7JHWPVYL2H",
            head: true,
            async: true,
          },
          {
            src: "https://www.googletagmanager.com/gtag/js?id=G-PHC4189WEP",
            head: true,
            async: true,
          },
          {
            src: "/js/gAnalytics.js",
            head: true,
            async: false,
          },
          {
            src: "/js/metricool.js",
            body: true,
            async: false,
          },
        ],
        __dangerouslyDisableSanitizers: ["script"],
      };
    },
    computed: {
      ...mapGetters(["newLocale"]),
      locale() {
        return this.newLocale;
      },
      app: function () {
        const dicApps = {
          website: icons.src("models1a"),
          register: icons.src("website"),
        };

        const appLogo = this.$route.path.includes("/admin") ? dicApps.register : dicApps.website;
        return {
          style: {
            "--app-icon": `url("${appLogo}")`,
          },
        };
      },
    },
    methods: {
      ...mapActions(["startApp"]),
      ...mapMutations(["setCurrentLang"]),
      loaded: function () {
        const app = document.getElementById("app");
        app.classList.remove("starting");
      },
    },
    watch: {
      locale(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$meta().refresh();
        }
      },
    },
    async beforeMount() {
      this.setCurrentLang();
      await this.startApp();
      this.loaded();
    },
    mounted() {
      this.$on("refreshlang", () => {
        this.appKey++;
      });
    },
  };
</script>

<style lang="scss">
  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    border-radius: 12px;
  }
</style>
