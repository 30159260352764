const icons = require("./icons");
const host = process.env.VUE_APP_HOST;
const env = process.env.VUE_APP_MODE || "local";
const dicHost = {
  autologin: {
    prod: "https://myspace1a.com",
    qa: "https://myspace1a.omgworldwidegroup.com",
    dev: "https://devmyspace1a.omgworldwidegroup.com",
  },
  administration: {
    prod: "https://administration.models1a.com",
    qa: "https://administration.omgworldwidegroup.com",
    dev: "https://devadministration.omgworldwidegroup.com",
  },
  reservations: {
    prod: "https://reservations.models1a.com",
    qa: "https://reservations.omgworldwidegroup.com",
    dev: "https://devreservations.omgworldwidegroup.com",
  },
  statistics: {
    prod: "https://1astats.models1a.com",
    qa: "https://1astats.omgworldwidegroup.com",
    dev: "https://dev1astats.omgworldwidegroup.com",
  },
  website: {
    prod: "https://www.models1a.com",
    qa: "https://models1a.omgworldwidegroup.com",
    dev: "https://devmodels1a.omgworldwidegroup.com",
  },
  chat: {
    prod: "https://messenger.models1a.com",
    qa: "https://messenger.omgworldwidegroup.com",
    dev: "https://devmessenger.omgworldwidegroup.com",
  },
  iot: {
    prod: "https://iot.models1a.com",
    qa: "https://iot.omgworldwidegroup.com",
    dev: "https://deviot.omgworldwidegroup.com",
  },
};
const dicPorts = {
  autologin: {
    app: "8080",
    api: "3000",
  },
  administration: {
    app: "8082",
    api: "3001",
  },
  reservations: {
    app: "8085",
    api: "3007",
  },
  statistics: {
    app: "8086",
    api: "3006",
  },
  website: {
    app: "8081",
    api: "3003",
  },
  chat: {
    app: "8091",
    api: "3091",
  },
  iot: {
    app: "5171",
    api: "4100",
  },
};
const dicRoutes = {
  autologin: "/main",
  website: "/admin",
};

const buildUrl = (host, port, route) => `${host}${port ? `:${port}` : ""}${route}`;
const getPort = (app, type) => (env === "local" ? dicPorts?.[app]?.[type] || "" : "");
const getHost = (app, env) => dicHost?.[app]?.[env] || host;
const getRoutes = (app, type) => {
  if (type === "api") {
    return "/api/v1";
  }
  return dicRoutes?.[app] || "";
};
const appConfig = (name, key, ipLimit, auth, out = false) => {
  const appHost = getHost(key, env);
  const appPort = getPort(key, "app");
  const apiPort = getPort(key, "api");
  const appRoute = getRoutes(key, "app");
  const apiRoute = getRoutes(key, "api");

  return {
    name,
    key,
    icon: icons.src(key),
    link: buildUrl(appHost, appPort, appRoute),
    api: buildUrl(appHost, apiPort, apiRoute),
    ipLimit,
    auth,
    out,
  };
};

module.exports = {
  apps: {
    autologin: "MySpace1A",
    administration: "Administración",
    reservations: "Reservas",
    statistics: "Estadísticas",
    website: "Registro",
    chat: "Chat",
    iot: "Iot",
  },
  apps_icons: [
    appConfig("MySpace1A", "autologin", true, true),
    appConfig("Administración", "administration", false, true),
    appConfig("Reservas", "reservations", false, true),
    appConfig("Estadísticas", "statistics", false, true),
    appConfig("Registro", "website", false, true),
    appConfig("Chat", "chat", true, true, true),
    appConfig("iot", "iot", true, true, true),
  ],
};
